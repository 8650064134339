import invariant from 'invariant';
import { ComponentProps, ReactElement } from 'react';

import { AnchorButton } from 'components/Button/AnchorButton';
import { CmsApiLink, CmsApiLinkType } from 'modules/cms/api/types/CmsApiLink';

import { CmsDownloadButtonLoadable } from './CmsDownloadButtonLoadable';
import { CmsLinkButtonLoadable } from './CmsLinkButtonLoadable';
import { CmsSubscribeButtonLoadable } from './CmsSubscribeButtonLoadable';

type Props = {
  buttonData: CmsApiLink;
  eventProperties: Record<string, unknown>;
  children: ReactElement<ComponentProps<typeof AnchorButton>>;
  blockId?: string;
  sectionIndex?: number;
};

/*
 * A generic button that handles all types of CMS "call to action" buttons
 */
export function CmsButton(props: Props) {
  const { eventProperties, buttonData, children, blockId, sectionIndex } =
    props;

  if (buttonData.type === CmsApiLinkType.S3_FILE) {
    return (
      <CmsDownloadButtonLoadable
        buttonData={buttonData}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
      >
        {children}
      </CmsDownloadButtonLoadable>
    );
  }

  if (buttonData.type === CmsApiLinkType.SUBSCRIBE) {
    invariant(blockId !== undefined, 'Missing block id for CmsButton');
    invariant(
      sectionIndex !== undefined,
      'Missing section index for CmsButton',
    );
    return (
      <CmsSubscribeButtonLoadable
        buttonData={buttonData}
        blockId={blockId}
        sectionIndex={sectionIndex}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
      >
        {children}
      </CmsSubscribeButtonLoadable>
    );
  }

  if (
    buttonData.type === CmsApiLinkType.URL ||
    buttonData.type === CmsApiLinkType.CMS_PAGE
  ) {
    return (
      <CmsLinkButtonLoadable
        buttonData={buttonData}
        eventProperties={{ cta_type: 'button', ...eventProperties }}
      >
        {children}
      </CmsLinkButtonLoadable>
    );
  }

  return null;
}
